import React, { FormEvent, useState } from "react";
import FormWrapper from "../../components/forms/FormWrapper";
import { FieldData } from "../../hooks/useForm";
import { FormField } from "../../helpers/types";
import CloseIcon from "../../components/icons/CloseIcon";
import Modal from "../../components/modal/Modal";
import { useMutation } from "@apollo/client";
import { SUBMIT_SETUP_REQUEST } from "../../graphql/queries";

export default function ManageSetup({
  fields,
  onComplete,
  title,
  name,
  selected,
}: {
  onComplete: Function;
  title: string;
  name: string;
  fields: FormField[];
  selected?: any;
}) {
  let initData = new Map();
  if (selected) {
    for (let f in selected) {
      initData.set(f, selected[f]);
    }
  }
  const [formData, setFormData] = useState(initData);
  const [submitSetup] = useMutation(SUBMIT_SETUP_REQUEST);
  function updateFormData({ name, value }: FieldData) {
    setFormData((prev) => {
      return new Map([...prev, [name, value]]);
    });
  }

  const handleSubmit = (ev: FormEvent) => {
    ev.preventDefault();
    let form = {
      name,
      fields: Array.from(formData, ([key, value]) => {
        return {
          name: key,
          value: value ? `${value}` : value,
          type: "text",
        };
      }),
      id: selected ? parseInt(selected.id) : undefined,
    };
    submitSetup({
      variables: {
        data: form,
      },
    }).then((res) => {
      onComplete();
    });
  };

  return (
    <Modal
      header={
        <div className="title flex justify-between items-center w-full">
          <h2>Create {title}</h2>
          <button onClick={() => onComplete()}>
            <CloseIcon />
          </button>
        </div>
      }
      handleClose={() => console.debug("Clicked...")}
      className="checklist-form"
    >
      <form onSubmit={handleSubmit}>
        <FormWrapper
          fields={fields}
          updateFormData={updateFormData}
          formData={formData}
        />
        <div className="flex justify-end items-center pt-3">
          <div className="form-controls flex gap-3 justify-end py-2 text-lg">
            <button
              className="border rounded px-5 py-1"
              onClick={() => onComplete()}
              type="button"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="border rounded px-5 py-1 bg-primary text-white btn-sm"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
}
