import React, { FormEvent, useState } from "react";
import FormWrapper from "../../components/forms/FormWrapper";
import { FieldData } from "../../hooks/useForm";
import { FormField } from "../../helpers/types";
import CloseIcon from "../../components/icons/CloseIcon";
import Modal from "../../components/modal/Modal";
import { useMutation } from "@apollo/client";
import { SUBMIT_SETUP_DELETE_REQ, SUBMIT_SETUP_REQUEST } from "../../graphql/queries";

export default function DeleteSetup({
  onComplete,
  title,
  name,
  selected,
}: {
  onComplete: Function;
  title: string;
  name: string;
  selected: any;
}) {
  let initData = new Map();
  if (selected) {
    for (let f in selected) {
      initData.set(f, selected[f]);
    }
  }
  const [formData, setFormData] = useState(initData);
  const [deleteSetup] = useMutation(SUBMIT_SETUP_DELETE_REQ);
  function updateFormData({ name, value }: FieldData) {
    setFormData((prev) => {
      return new Map([...prev, [name, value]]);
    });
  }

  const handleSubmit = (ev: FormEvent) => {
    ev.preventDefault();
    let form = {
      name,
      id: parseInt(selected.id)
    };
    deleteSetup({
      variables: {
        data: form,
      },
    }).then((res) => {
      onComplete();
    });
  };

  return (
    <Modal
      header={
        <div className="title flex justify-between items-center w-full">
          <h2>Delete {title}</h2>
          <button onClick={() => onComplete()}>
            <CloseIcon />
          </button>
        </div>
      }
      handleClose={() => console.debug("Clicked...")}
      className="checklist-form w-48"
    >
      <form onSubmit={handleSubmit}>
        <FormWrapper
          fields={[]}
          updateFormData={updateFormData}
          formData={formData}
        />
        <p>Are you sure you want to delete this setup?</p>
        <div className="flex justify-end items-center pt-3">
          <div className="form-controls flex gap-3 justify-end py-2 text-lg">
            <button
              className="border rounded px-5 py-1"
              onClick={() => onComplete()}
              type="button"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="border rounded px-5 py-1 text-red-500 btn-sm"
            >
              Delete
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
}
