import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_ALL_ROLES,
  GET_ALL_USERS,
  GET_SETUPS,
  SUBMIT_CREATE_ROLE_REQUEST,
  SUBMIT_CREATE_USER_REQUEST,
} from "../../../graphql/queries";
import Table from "../../../components/tabular/Table";
import FormWrapper from "../../../components/forms/FormWrapper";
import Modal from "../../../components/modal/Modal";
import { FormField } from "../../../helpers/types";

export default function UsersPage() {
  const [getUsers, { loading }] = useLazyQuery(GET_ALL_USERS, {
    fetchPolicy: "no-cache",
  });
  const [getSetups] = useLazyQuery(GET_SETUPS, {
    fetchPolicy: "network-only",
  });
  const [data, setData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [transporters, setTransporters] = useState([]);
  const [submitCreateUserReq, { loading: submitLoading }] = useMutation(
    SUBMIT_CREATE_USER_REQUEST,
  );
  const [getRoles] = useLazyQuery(GET_ALL_ROLES, {
    fetchPolicy: "no-cache",
  });
  const [state, setState] = useState<{
    edit: boolean;
    selected: any;
    new: boolean;
  }>({ new: false, edit: false, selected: undefined });
  const [formData, setFormData] = useState(new Map<string, any>());
  const handleOnChange = ({ name, value }: any) => {
    console.log({ name, value });
    setFormData((prev) => {
      let updated = new Map(prev);
      updated.set(name, value);
      return updated;
    });
  };

  const refreshList = () => {
    getUsers({
      variables: {},
    }).then((res) => {
      console.log({ data: res.data.getUsers });
      setData(res.data.getUsers);
    });
  };
  const submitCreate = (ev: any) => {
    ev.preventDefault();
    let submitData: any = {
      email: formData.get("email"),
      firstName: formData.get("firstName"),
      lastName: formData.get("lastName"),
      id: state.selected ? state.selected.id : null,
      roleId: formData.get("roleId"),
    };
    let transId = parseInt(formData.get("transporterId") || 0);
    submitData["transporterId"] = transId || null;
    console.log({formData, submitData})
    submitCreateUserReq({
      variables: {
        data: {...submitData},
      },
    }).then((res) => {
      refreshList();
      setState((prev) => ({
        ...prev,
        edit: false,
        new: false,
        selected: null,
      }));
    });
  };

  const transChoices = transporters.map((r: any) => ({
    label: r.name,
    value: `${r.id}`,
  }));
  transChoices.unshift({ label: "N/A", value: "0" });

  const formFields: FormField[] = [
    {
      name: "email",
      label: "Email",
      cols: 2,
    },
    {
      name: "firstName",
      label: "First Name",
      cols: 2,
    },
    {
      name: "lastName",
      label: "Last Name",
      cols: 2,
    },
    {
      name: "roleId",
      label: "Role",
      cols: 2,
      type: "select",
      choices: roles.map((r: any) => ({ label: r.name, value: r.id })),
    },
    {
      name: "transporterId",
      label: "Transporter",
      cols: 2,
      type: "select",
      options: { required: false },
      choices: transChoices,
    },
  ];

  const TABLE_COLUMNS = [
    { label: "ID", name: "id" },
    {
      label: "Email",
      name: "email",
    },
    {
      name: "firstName",
      label: "First Name",
    },
    {
      name: "lastName",
      label: "Last Name",
    },
    {
      label: "Role",
      name: "role",
      render: ({ row }: any) => <span>{row.role.name}</span>,
    },
    {
      label: "Transporter",
      name: "transporterId",
      render: ({ row }: any) => {
        if (!row.transporter) return "N/A";
        let trans = row.transporter.fields.find((f: any) => f.name === "name");
        return trans?.value || "Unkown";
      },
    },
    {
      name: "action",
      render: ({ row }: any) => (
        <div className="flex gap-1 items-center">
          <button
            onClick={() => {
              setFormData((prev) => {
                console.log({ row });
                prev.set("email", row.email);
                prev.set("roleId", row.role.id);
                prev.set("transporterId", `${row.transporter?.id || "0"}`);
                return new Map(prev);
              });
              setState((prev) => ({ ...prev, edit: true, selected: row }));
            }}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  console.log({ formData });
  console.log({ transChoices });

  useEffect(() => {
    refreshList();
    getRoles({ variables: {} }).then((res) => setRoles(res.data.getRoles));
    getSetups({
      variables: {
        names: ["transporters"],
      },
    }).then((res) => {
      let trans = res.data.getSetups.records.map((r: any) => {
        let found = r.fields.find((f: any) => f.name === "name");
        return { name: found?.value || "Unkown", id: r.id };
      });
      setTransporters(trans);
    });
  }, []);

  return (
    <div>
      <div>
        <Table
          columns={[...TABLE_COLUMNS]}
          data={data}
          loading={loading}
          title={<b>Users</b>}
          components={{
            toolbar: () => (
              <div className="flex gap-1">
                <button
                  className="bg-blue-500 px-2 py-1 text-white border rounded"
                  onClick={() => {
                    setState((prev) => ({ ...prev, new: true }));
                    setFormData(new Map());
                  }}
                >
                  New user
                </button>
              </div>
            ),
          }}
        />
      </div>
      {state.edit && (
        <Modal
          className="w-48"
          modalId="editRole"
          header={<div>Edit user: {state.selected.email}</div>}
          handleClose={() => setState((prev) => ({ ...prev, edit: false }))}
        >
          <form onSubmit={submitCreate}>
            <FormWrapper
              fields={formFields}
              formData={formData}
              updateFormData={handleOnChange}
            />
            <div className="flex justify-end items-center py-1">
              <button
                disabled={submitLoading}
                type="submit"
                className="bg-blue-500 px-2 py-1 text-white border rounded"
              >
                {submitLoading ? "Please wait ..." : "Submit"}
              </button>
            </div>
          </form>
        </Modal>
      )}
      {state.new && (
        <Modal
          className="w-72"
          modalId="editRole"
          header={<div>New user</div>}
          handleClose={() => setState((prev) => ({ ...prev, new: false }))}
        >
          <form onSubmit={submitCreate}>
            <FormWrapper
              fields={formFields}
              formData={formData}
              updateFormData={handleOnChange}
            />
            <div className="flex justify-end items-center py-1">
              <button
                disabled={submitLoading}
                type="submit"
                className="bg-blue-500 px-2 py-1 text-white border rounded"
              >
                {submitLoading ? "Please wait ..." : "Submit"}
              </button>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
}
