import { useEffect, useState } from "react";
import Table from "../../components/tabular/Table";
import ManageSetup from "./ManageSetup";
import { FormField, SetupKind } from "../../helpers/types";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_USERS, GET_SETUPS } from "../../graphql/queries";
import BulkSetups from "./BulkSetups";
import usePagination from "../../hooks/usePagination";
import DeleteSetup from "./DeleteSetup";

type SetupDataType = {
  name: SetupKind;
  singular: string;
  fields: FormField[];
};

function PageFilter({ handleFilter }: { handleFilter: Function }) {
  const [searchValue, setSearchValue] = useState("");

  const onSearch = (ev: any) => {
    setSearchValue(ev.target.value);
    handleFilter(ev.target.value);
  };

  const submitSearch = (ev: any) => {
    ev.preventDefault();
  };
  return (
    <form className="flex-1 " onSubmit={submitSearch}>
      <input
        type="search"
        name="search"
        placeholder="Filter ..."
        className="px-3 py-1 rounded-lg w-full"
        onChange={onSearch}
        defaultValue={searchValue}
      />
    </form>
  );
}

const SETUP_DATA: SetupDataType[] = [
  {
    name: "zones",
    singular: "zone",
    fields: [
      { name: "name", label: "Name", options: { required: true } },
      {
        name: "zone_manager_id",
        label: "Zone Manager",
        options: { required: true },
        type: "select_users",
      },
    ],
  },
  {
    name: "transporters",
    singular: "transporter",
    fields: [
      { name: "name", label: "Name", options: { required: true } },
      { name: "email", label: "Email", options: { required: true } },
      { name: "phone", label: "Phone", options: { required: true } },
      { name: "phone", label: "Phone", options: { required: true } },
      { name: "alt_phone", label: "Alt. Phone" },
      {
        name: "zone_id",
        label: "Zone",
        type: "select_setups",
        options: {
          required: true,
          setup: "zones",
        },
        setupKind: "zones",
      },
      {
        name: "account_manager_id",
        label: "Account Manager",
        type: "select_users",
        options: {
          required: true,
        },
      },
    ],
  },
  {
    name: "customers",
    singular: "customer",
    fields: [
      { name: "name", label: "Name", options: { required: true } },
      { name: "email", label: "Email", options: { required: true } },
      { name: "phone", label: "Phone", options: { required: true } },
      { name: "alt_phone", label: "Alt. Phone" },
      {
        name: "zone_id",
        label: "Zone",
        type: "select_setups",
        options: {
          required: true,
          setup: "zones",
        },
        setupKind: "zones",
      },
      {
        name: "account_manager_id",
        label: "Account Manager",
        type: "select_users",
        options: {
          required: true,
        },
      },
    ],
  },
  {
    name: "sites",
    singular: "site",
    fields: [
      { name: "name", label: "Name", cols: 2, options: { required: true } },
    ],
  },
  {
    name: "drivers",
    singular: "driver",
    fields: [
      { name: "name", label: "Name", options: { required: true } },
      { name: "licence_no", label: "Licence No" },
      { name: "email", label: "Email", options: { required: true } },
      { name: "phone", label: "Phone", options: { required: true } },
      { name: "alt_phone", label: "Alt. Phone" },
    ],
  },
];

export default function SetupsPage() {
  const [kind, setKind] = useState<SetupKind>("transporters");
  const [openNew, setOpenNew] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openBulk, setOpenBulk] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [selected, setSelected] = useState<any>(null);
  const [totalRecords, setTotalRecords] = useState(1);
  const { Pagination, pageInfo, setPageInfo } = usePagination(10);
  const setup = SETUP_DATA.find((s) => s.name === kind);
  const [getSetups, { loading }] = useLazyQuery(GET_SETUPS, {
    fetchPolicy: "network-only",
  });
  const [getUsers, { data: users }] = useLazyQuery(GET_ALL_USERS, {
    fetchPolicy: "network-only",
  });

  const [filter, setFilter] = useState("");
  const handleFilter = (value: string) => {
    console.log({ value });
    setFilter(value);
  };

  useEffect(() => {
    getUsers({
      variables: {
        data: {},
      },
    }).then((res) => console.log({ res }));
  }, []);

  function refresh(page: number = 1) {
    getSetups({
      variables: {
        names: [kind],
        page: page,
        limit: pageInfo.limit,
        filter: filter,
      },
    }).then((res) => {
      if (res.data) {
        let setups = res.data.getSetups;
        setTotalRecords((prev) => setups.totalRecords);
        let sData = setups.records.map((s: any) => {
          let fields = s.fields.reduce(function (map: any, f: any) {
            map[f.name] = f.value;
            return map;
          }, {});

          return {
            id: s.id,
            ...fields,
          };
        });
        setData(sData);
      }
    });
  }

  useEffect(() => {
    setPageInfo((prev) => ({ ...prev, page: 1 }));
  }, [kind, pageInfo.limit]);

  useEffect(() => {
    refresh(pageInfo.page);
  }, [pageInfo, filter]);

  function getUser(userId: string) {
    let found = users.getUsers?.find(
      (u: any) => parseInt(u.id) === parseInt(userId),
    );
    if (!found) return null;
    return {
      id: parseInt(userId),
      name: `${found.firstName} ${found.lastName}`,
    };
  }

  if (!setup) return null;
  const columns = [{ name: "id", label: "#" }, ...setup.fields].map((c) => {
    if (c.type === "select_users") {
      return {
        ...c,
        render: ({ row }: any) => {
          for (let f of ["zone_manager_id"]) {
            return getUser(row[f])?.name;
          }
        },
      };
    }
    return { ...c };
  });

  return (
    <div>
      <div className="toolbar flex justify-between px-2 py-2 items-center gap-10">
        <h3 className="capitalize">{`List of ${kind}`}</h3>
        <div className="flex gap-1 flex-1">
          <input
            type="search"
            name="search"
            placeholder="Filter ..."
            className="flex-1 px-3 py-1 rounded-lg w-full"
            onChange={(ev) => handleFilter(ev.target.value)}
            defaultValue={filter}
          />
          <select
            value={kind}
            className="px-2 rounded capitalize border"
            onChange={(ev) => setKind(ev.target.value as SetupKind)}
          >
            {SETUP_DATA.map((s, idx) => (
              <option key={idx} value={s.name}>
                {s.name}
              </option>
            ))}
          </select>
          <button
            className="bg-primary px-2 py-1 rounded text-white"
            onClick={() => setOpenNew(true)}
          >
            Add New
          </button>
          <button
            className="bg-green-400 px-2 py-1 rounded text-white"
            onClick={() => setOpenBulk(true)}
          >
            Bulk Setups
          </button>
        </div>
      </div>
      <Table
        columns={[
          ...columns,
          {
            name: "action",
            render: ({ row }: any) => {
              return (
                <div className="flex gap-2">
                  <button
                    className="rounded border px-2 py-1 bg-gray-100"
                    onClick={() => {
                      setSelected(row);
                      setOpenNew(true);
                    }}
                  >
                    View/Edit
                  </button>
                  <button
                    className="rounded border px-2 py-1 bg-gray-100 text-red-400"
                    onClick={() => {
                      setSelected(row);
                      setOpenDelete(true);
                    }}
                  >
                    Delete
                  </button>
                </div>
              );
            },
          },
        ]}
        data={data}
        loading={loading}
        components={{
          toolbar: () => <p></p>,
        }}
      />
      {openNew && (
        <ManageSetup
          selected={selected}
          name={setup.name}
          title={setup.singular}
          fields={setup.fields}
          onComplete={() => {
            setOpenNew(false);
            refresh(1);
          }}
        />
      )}
      {openDelete && (
        <DeleteSetup
          selected={selected}
          name={setup.name}
          title={setup.singular}
          onComplete={() => {
            setOpenDelete(false);
            refresh(1);
          }}
        />
      )}
      {openBulk && (
        <BulkSetups
          name={setup.name}
          title={setup.singular}
          fields={[
            {
              name: "file",
              type: "file",
              label: "Excel File",
              cols: 2,
            },
          ]}
          onComplete={() => {
            setOpenBulk(false);
            refresh(1);
          }}
        />
      )}

      <Pagination totalRecords={totalRecords} />
    </div>
  );
}
