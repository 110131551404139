import { FormField } from "../../helpers/types";
import FormInputField from "./inputs/FormInputField";

function FormWrapper({
   fields,
   updateFormData,
   formData,
}: {
   fields: FormField[];
   updateFormData: Function;
   formData: Map<string, any>;
}) {
   console.log({formData})
   return (
      <div className='form grid grid-cols-2 grid-flow-row gap-4'>
         {fields.map((f, idx) => (
            <FormInputField key={idx} onChange={updateFormData} {...f} formData={formData} />
         ))}
      </div>
   );
}

export default FormWrapper;
